.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #4e80ee; /* Thumb color (the draggable part) */
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color (the area the thumb moves in) */
}

/* Firefox */
scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

scrollbar-thumb {
  background-color: #4e80ee; /* Thumb color */
  border-radius: 4px;
}

scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

/* For systems supporting scrollbar-width (Firefox) */
* {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #4e80ee #f1f1f1; /* Thumb color and track color */
}
